<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("schoolPages.title") }}
          </template>
          <template v-slot:toolbar>
            <b-button @click="addPage()">{{ $t("common.new") }}</b-button>
          </template>
          <template v-slot:body>
            <table class="table table-bordered" id="list">
              <thead class="thead-light">
              <tr>
                <th class="text-left">{{ $t("certificates.list.id") }}</th>
                <th class="text-left">{{ $t("certificates.list.image") }}</th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in pages" :key="item.id">
                <td>{{ item.id }}</td>
                <td>
                  <img width="50px" :src="'http://97.school.devel/uploads/school/' + item.schoolId + '/' + item.image"/>
                </td>
                <td>
                  <b-button @click="editPage(item)" variant="primary">
                    {{ $t("common.edit") }}
                  </b-button>
                  <b-button @click="deleteCertificate(item)"
                            variant="danger"
                  >
                    {{ $t("common.delete") }}
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
            <b-modal id="modal-1" ref="certificate-modal">
              <template v-slot:modal-title>
                {{ $t("common.new") }}
              </template>
              <b-form>
                <b-form-group id="input-group-1" label-for="input-1">
                  <template v-slot:label>
                    {{ $t("certificates.list.image") }}
                  </template>
                  <input type="file" @change="uploadFile" ref="file">
                </b-form-group>
              </b-form>
              <template v-slot:modal-footer>
                <b-button type="submit" @click="onSubmit" variant="primary">{{
                    $t("common.save")
                  }}
                </b-button>
                <b-button type="reset" @click="onReset" variant="danger">{{
                    $t("common.cancel")
                  }}
                </b-button>
              </template>
            </b-modal>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
export default {
  name: "certificates",
  components: {
    KTPortlet
  },
  data() {
    return {
      pages: [],
      files: null,
      dialog: true,
      test:"",
      form: {
        id: "",
      },
      modelState: {}
    };
  },
  mounted() {
    
    this.reloadData();
  },
  methods: {
    reloadData() {
      ApiService.querySecured("schoolPagesAdmin/certificates")
          .then(({ data }) => {
            this.pages = data;
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    editPage(page) {
      this.form = page;
      this.$refs["certificate-modal"].show();
    },
    addPage() {
      this.form = {};
      this.$refs["certificate-modal"].show();
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["certificate-modal"].hide();
    },
    uploadFile() {
      this.files = this.$refs.file.files[0];
    },
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;
      ApiService.importSecured("schoolPagesAdmin/saveCertificate", this.files, this.form)
          .then(function() {
            $this.$refs["certificate-modal"].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({ response }) => {
            console.log(response);
            $this.reloadData();
          });
    },
    cleanModel() {
      this.form = {};
    },
    deleteCertificate(certificate) {
      if(confirm("Удалить сертификат?")){
        let $this = this;

        ApiService.postSecured("schoolPagesAdmin/deleteCertificate", {id:certificate.id})
            .then(function () {
              $this.reloadData();
            })
            .catch(({response}) => {
              console.log(response);
              $this.reloadData();
            });
      }
    },
  }
};
</script>
